.Chat {
  border-top: 1px solid black;
  display: flex;
  flex-basis: 250px;
  flex-direction: column;
}

.Input {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 2em;
}

.Input > button {
  width: 3em;
}

.MessageList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow-y: scroll;
}

.MessageList > :first-child {
  margin-top: auto;
}

.Button {
  width: 3em;
}
