.DialogExplorer {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.MyDialog {
  text-align: left;
}

.TheirDialog {
  text-align: right;
}