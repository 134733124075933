.Game {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
}

.Main {
  align-items: stretch;
  border-right: solid 1px black;
  display: flex;
  flex-basis: 300px;
  flex-direction: column;
  flex-grow: 4;
}

.SideBar {
  align-items: stretch;
  display: flex;
  flex-basis: 300px;
  flex-grow: 4;
  flex-direction: column;
}
