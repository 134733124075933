.ItemInstanceInspector {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ItemInstanceInspectorList {
  display: block;
}

.ItemInstanceInspector h4 {
  margin: 0;
}